import { fetchPublic, useWindowSize } from "../../../utils";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Header } from "../Header";
import { Footer } from "../footer";
import { useUtility } from "../../../utils/context/UtilityContext";
import { Subtitle } from "../Subtitle";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ProducSlider } from "./ProductSlider";

interface ProviderData {
  name: string;
  image: string;
}

interface CategoryData {
  category: string;
  providers: ProviderData[];
}

export const ProductsPages = () => {
  const [providersByCategory, setProvidersByCategory] = useState<CategoryData[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const { marginBottomHeader } = useUtility();
  const [showDialog, setShowDialog] = useState(false);
  const { isPC } = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProviders();
    const dialogPreference = localStorage.getItem("sliderDialogShown");
    if (!dialogPreference) {
      setShowDialog(true);
    }
  }, []);

  const fetchProviders = async () => {
    setLoading(true);
    try {
      const result = await fetchPublic(`/public/product/providers`);
      if (result.providersByCategory) {
        setProvidersByCategory(result.providersByCategory);
      }
    } catch (error) {
      console.error("Failed to fetch providers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectProvider = (provider: string) => {
    navigate(`/provider/${provider}`);
  };

  const handleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    setProvidersByCategory((prevCategories) =>
      prevCategories.map((categoryData) => ({
        ...categoryData,
        providers: categoryData.providers.sort((a, b) =>
          sortOrder === "asc"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        ),
      }))
    );
  };

  const handleCloseDialog = (dontShowAgain: boolean) => {
    if (dontShowAgain) {
      localStorage.setItem("sliderDialogShown", "true");
    }
    setShowDialog(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll`}
    >
      <Header />
      <div
        className={`flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll p-8 w-full ${
          isPC ? "px-16" : "px-1"
        }`}
      >
        <Dialog open={showDialog} onClose={() => handleCloseDialog(false)}>
          <DialogTitle>Le Saviez-vous ?</DialogTitle>
          <DialogContent>
            Vous pouvez faire défiler vers la droite pour voir plus d'articles !
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleCloseDialog(false)}
              sx={{ color: "#104b7b" }}
            >
              OK
            </Button>
            <Button
              onClick={() => handleCloseDialog(true)}
              sx={{ color: "#104b7b" }}
            >
              Ne plus afficher
            </Button>
          </DialogActions>
        </Dialog>

        <Button variant="contained" onClick={handleSort}>
          Trier par nom ({sortOrder === "asc" ? "A-Z" : "Z-A"})
        </Button>
        {providersByCategory.map(({ category, providers }) => (
          <div key={category} className="w-full">
            <div
              className="cursor-pointer items-center justify-center flex w-full text-center"
              onClick={() => navigate(`/products/${category}`)}
            >
              <Subtitle
                title={category}
                textColor="#104b7b"
                textAlign="items-center"
                textSize="40px"
                loading={loading}
              />
            </div>

            <ProducSlider
              loading={loading}
              providers={providers.map((provider) => provider.name)}
              providerImages={providers.map((provider) => ({
                provider: provider.name,
                image: provider.image,
              }))}
              onSelectProvider={handleSelectProvider}
            />
          </div>
        ))}
      </div>

      <Footer />
    </motion.div>
  );
};
