import React from 'react';
import { Grid } from '@mui/material';

import { Product } from './Product';
import { ProductType } from '../../../utils';

interface ProductListProps {
    products: ProductType[];
    loading?: boolean;
  }
export const ProductList: React.FC<ProductListProps> = ({products, loading=false}) => {

  return (
      <Grid container justifyContent="center" sx={{backgroundColor:"#f5f5f6"}}>
        {products.map((product, index) => (
          <Product key={index} product={product} loading={loading} />
        ))}
      </Grid>
    );
  };
  