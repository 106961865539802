import React, { useEffect, useState } from "react";
import { useAuth } from "utils/context";
import {
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { apiSrcUrl, getToken } from "utils";

export const AddTransaction = () => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    type: "",
    amountSpent: "",
    amountReceived: "",
    userID: user?.userID || "", // Initialize with userID from context
    userName: user?.username || "", // Initialize with userName from context
    description: "",
  });
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const retrievedToken = getToken();
    setToken(retrievedToken);

    if (retrievedToken) {
      console.log("Token is available:", retrievedToken);
    }
  }, []);

  // Separate handler for SelectChangeEvent
  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { type, amountSpent, amountReceived, userID, userName, description } =
      formData;

    // Validate numeric fields
    if (isNaN(parseFloat(amountSpent)) || isNaN(parseFloat(amountReceived))) {
      alert(
        "Please enter valid numeric values for Amount Spent and Amount Received."
      );
      return;
    }

    const payload = {
      type,
      amountSpent: parseFloat(amountSpent),
      amountReceived: parseFloat(amountReceived),
      userID, // Include userID from the form data
      userName, // Include userName from the form data
      description,
    };

    try {
      const response = await fetch(`${apiSrcUrl}/private/transactions`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData);
        alert(`Error: ${errorData.error || "Failed to add transaction"}`);
        return;
      }

      const responseData = await response.json();
      console.log("Transaction added successfully:", responseData);
      alert("Transaction added successfully!");

      // Reset form
      setFormData({
        type: "",
        amountSpent: "",
        amountReceived: "",
        userID: user?.userID || "",
        userName: user?.username || "",
        description: "",
      });
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while adding the transaction.");
    }
  };

  return (
    <Box className="p-6 bg-gray-100 rounded-md shadow-md max-w-lg mx-auto">
      <Typography variant="h4" className="text-center mb-6 text-blue-600">
        Add Transaction
      </Typography>
      <form onSubmit={handleSubmit} className="space-y-4">
        <FormControl fullWidth className="bg-white">
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            id="type"
            name="type"
            value={formData.type}
            onChange={handleSelectChange} // Use handleSelectChange for Select component
            required
          >
            <MenuItem value="manual recharge">Manual Recharge</MenuItem>
            <MenuItem value="purchase">Purchase</MenuItem>
            <MenuItem value="refund">Refund</MenuItem>
            <MenuItem value="transfer">Transfer</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Amount Spent"
          name="amountSpent"
          type="number"
          value={formData.amountSpent}
          onChange={handleChange}
          required
          fullWidth
          className="bg-white"
        />
        <TextField
          label="Amount Received"
          name="amountReceived"
          type="number"
          value={formData.amountReceived}
          onChange={handleChange}
          required
          fullWidth
          className="bg-white"
        />
        <TextField
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
          className="bg-white"
        />
        <TextField
          label="User Name"
          name="userName"
          value={formData.userName}
          fullWidth
          className="bg-gray-100 cursor-not-allowed"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full"
        >
          Add Transaction
        </Button>
      </form>
    </Box>
  );
};
