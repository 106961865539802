import React, { useRef } from "react";
import { Typography } from "@mui/material";
import { motion, useAnimation, useInView } from "framer-motion";
import { useWindowSize } from "utils";
import SuspenseWrapper from "./SuspenseWrapper";

interface SubtitleProps {
  title: string;
  textColor?: string;
  textSize?: string;
  textAlign?: string;
  loading?: boolean;
}

const circleVariants = {
  hidden: { scale: 0, y: -50 },
  visible: { scale: 1, y: 0 },
};

export const Subtitle: React.FC<SubtitleProps> = ({
  title,
  textColor = "#000",
  textSize = "32px",
  textAlign = "items-start",
  loading = false,
}) => {
  const ref = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(ref, { once: false });
  const { isMobile } = useWindowSize();
  React.useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [isInView, controls]);

  return (
    <motion.div
      className={`flex ${textAlign} w-full flex-col justify-center ${
        !isMobile ? "ml-20" : ""
      }`}
      ref={ref}
    >
      <div
        className={`flex flex-col items-center justify-center border-primary gap-1`}
      >
        <SuspenseWrapper
          loading={loading}
          skeletonProps={{
            variant: "text",
            width: "200px",
            height: "40px",
            animation: "wave",
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{ color: textColor, fontSize: textSize }}
          >
            {title}
          </Typography>
        </SuspenseWrapper>

        <div className="border-2 w-full border-primary"></div>
        <motion.div
          className="rounded-full w-4 h-4 border-4 bg-primary border-primary"
          variants={circleVariants}
          initial="hidden"
          animate={controls}
          transition={{ type: "spring", stiffness: 100, damping: 10 }}
        />
      </div>
    </motion.div>
  );
};
