import React, { useState, useEffect } from "react";
import Slider, { Settings } from "react-slick";
import { CardContent, Typography, Skeleton } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { apiSrcUrl, useWindowSize } from "utils";
import { getArticlesToShow } from "./product";
import SuspenseWrapper from "../shared/SuspenseWrapper";

interface Article {
  title: string;
  link: string;
  pubDate: string;
  description: string;
  imageUrl: string;
}

interface RssFeedProps {
  search: string;
}

export const RssFeed: React.FC<RssFeedProps> = ({ search }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const { isMobile, width } = useWindowSize();
  const [loading, setLoading] = useState(true);

  const updateSlidesToShow = (width: number, articlesLength: number) => {
    const newSlidesToShow = getArticlesToShow(width, articlesLength);
    setSlidesToShow(newSlidesToShow > 3 ? 3 : newSlidesToShow);
  };

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${apiSrcUrl}/public/feed?q=${encodeURIComponent(search)}`
        );
        const data = await response.json();
        setArticles(data);
        updateSlidesToShow(window.innerWidth, data.length);
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchNews();
  }, [search]);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      updateSlidesToShow(newWidth, articles.length);
    };

    window.addEventListener("resize", handleResize);
    updateSlidesToShow(width, articles.length);

    return () => window.removeEventListener("resize", handleResize);
  }, [articles.length, width]);

  const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIosIcon
        className={className}
        style={{
          ...style,
          display: "block",
          color: isHovered ? "#000" : "#ccc",
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIosIcon
        className={className}
        style={{
          ...style,
          display: "block",
          color: isHovered ? "#000" : "#ccc",
        }}
        onClick={onClick}
      />
    );
  };

  const settings: Settings = {
    infinite: articles.length > 1,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: articles.length > 1,
    swipeToSlide: true,
    draggable: true,
    centerMode: !isMobile,
    centerPadding: isMobile ? "0px" : "60px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="p-4 w-full"
    >
      <Slider {...settings}>
        {loading
          ? // Display skeletons during loading
            Array.from({ length: slidesToShow }).map((_, index) => (
              <div
                key={index}
                className="mx-4 p-8 flex flex-col justify-between"
              >
                <SuspenseWrapper
                  loading={loading}
                  skeletonProps={{
                    variant: "rectangular",
                    width: "100%",
                    height: "200px",
                    animation: "wave",
                  }}
                >
                  <div className="border-2 rounded-bl flex flex-col h-full">
                    <Skeleton variant="rectangular" height={200} />
                    <CardContent>
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="text" width="60%" />
                    </CardContent>
                  </div>
                </SuspenseWrapper>
              </div>
            ))
          : articles.length > 0
          ? articles.map((article, index) => (
              <div
                className={`mx-4 p-8 ${
                  isMobile
                    ? "h-[650px] w-full"
                    : `w-[200px] ${
                        slidesToShow === 2 ? "h-[700px]" : "h-[650px]"
                      }`
                } flex flex-col justify-between`}
                key={index}
              >
                <div className="border-2 rounded-bl flex flex-col h-full">
                  <div className="w-full min-h-[200px] max-h-[200px] overflow-hidden">
                    <img
                      src={article.imageUrl}
                      alt={article.title}
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <CardContent className="flex flex-col flex-grow">
                    <Typography variant="h6" component="div" gutterBottom>
                      {article.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="flex-grow"
                    >
                      {article.description.slice(0, 200)}...
                    </Typography>
                    <a
                      href={article.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline mt-2 block"
                    >
                      Read more
                    </a>
                  </CardContent>
                </div>
              </div>
            ))
          : !loading && (
              <div className="flex w-full h-full justify-center items-center">
                <Typography variant="h6" color="text.secondary">
                  Pas d'article trouvé
                </Typography>
              </div>
            )}
      </Slider>
    </div>
  );
};
