import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { User, AuthContextType } from "../../types/index";
import { authToken, Role, ROLES } from "../../constant/index";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "utils/firebase";


export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<Role>(ROLES.VISITOR);
  const [isLogged, setIsLogged] = useState(false);


  const logout = useCallback( async () => {
    setUser(null);
    setRole(ROLES.VISITOR);
    setIsLogged(false);
    await signOut(auth);
    localStorage.removeItem(authToken);
  }, []);

  const login = useCallback(async (user: User) => {
   
    if (user) {
      setUser(user);
      setRole(user.role);
      setIsLogged(true);
    } else {
      localStorage.removeItem(authToken);
      setUser(null);
      setRole(ROLES.VISITOR);
      setIsLogged(false);
      await signOut(auth);
    }
  }, []);


  useEffect(() => {
    const auth = getAuth();
   

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
       
      
      } else {
       
        logout();
      }
    });
    return () => unsubscribe();
  }, [ logout]);
  return (
    <AuthContext.Provider
      value={{ user, login, logout, role, isLogged,setIsLogged }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};