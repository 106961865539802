import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { ProductCardProps, SetFlag, useWindowSize } from "../../../utils";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useUtility } from "../../../utils/context/UtilityContext";
import { useNavigate, useParams } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const BSBCoin = React.lazy(() => import("../BSBCoin"));
const circleVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};

export const Product: React.FC<ProductCardProps> = ({
  product,
  loading = false,
}) => {
  const { provider } = useParams();
  const { addToCart } = useUtility();
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const handleNavigate = async () => {
    console.log(typeof provider);
    if (typeof provider === "string") {
      await addToCart(product);
      setSnackbarMessage(
        `Carte cadeau ${product.name} ${product.price} ${product.currency} ajouté au panier`
      );
      setOpenSnackbar(true);
      navigate(`/transaction`);
    } else {
      navigate(`/provider/${product.provider}`);
    }
  };

  const handleAddToCart = (e: React.MouseEvent) => {
    e.stopPropagation();
    addToCart(product);

    setSnackbarMessage(
      `Carte cadeau ${product.name} ${product.price} ${product.currency} ajouté au panier`
    );
    setOpenSnackbar(true);
  };
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (provider === product.provider) {
      controls.start("visible");
    } else if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls, provider, product.provider]);

  return (
    <>
      <motion.div
        ref={ref}
        variants={circleVariants}
        initial="hidden"
        animate={controls}
        transition={{ duration: 1, ease: "easeInOut" }}
        onClick={handleNavigate}
        className="cursor-pointer"
      >
        <Card sx={{ maxWidth: isMobile ? 270 : 200, margin: 2, padding: 1 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SuspenseWrapper
                loading={loading}
                skeletonProps={{
                  variant: "text",
                  width: "60%",
                  height: "20px",
                  animation: "wave",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  noWrap={true}
                >
                  {product.category}
                </Typography>
              </SuspenseWrapper>
              <SuspenseWrapper
                loading={loading}
                skeletonProps={{
                  variant: "rounded",
                  width: "40px",
                  height: "25px",
                  animation: "wave",
                }}
              >
                <SetFlag
                  countryCode={product.countryCode}
                  countryName={product.country}
                />
              </SuspenseWrapper>
            </Box>
            <SuspenseWrapper
              loading={loading}
              skeletonProps={{
                variant: "rounded",
                width: "100%",
                height: "80px",
                animation: "wave",
                sx: { marginTop: "20px" },
              }}
            >
              <CardMedia
                component="img"
                image={product.image}
                alt={product.name}
                sx={{ height: 140, objectFit: "contain", margin: "10px 0" }}
              />
            </SuspenseWrapper>
            <SuspenseWrapper
              loading={loading}
              skeletonProps={{
                variant: "text",
                width: "100%",
                height: "50px",
                animation: "wave",
                sx: { marginTop: "20px" },
              }}
            >
              <Typography
                variant={isMobile? "h4" : "h5"}
                component="div"
                textAlign={"center"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                marginBottom={"10px"}
                sx={{
                  height: "100px",
                  lineHeight: "1.5",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {product.name} {product.price} {product.currency}
              </Typography>
            </SuspenseWrapper>

            <Typography
              variant={isMobile? "h6" : "body1"}
              component="div"
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"4px"}
              color="textSecondary"
              sx={{
                height: "80px",
                lineHeight: "1.5",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "horizontal",
              }}
            >
              <SuspenseWrapper
                loading={loading}
                skeletonProps={{
                  variant: "text",
                  width: "100px",
                  height: "20px",
                  animation: "wave",
                }}
              >
                {product.priceUSD} BSB coins{" "}
              </SuspenseWrapper>

              <SuspenseWrapper
                loading={loading}
                skeletonProps={{
                  variant: "circular",
                  width: "50px",
                  height: "50px",
                  animation: "wave",
                }}
              >
                <BSBCoin />
              </SuspenseWrapper>
            </Typography>
            <Box
              display="flex"
              padding={"4px"}
              justifyContent="flex-end"
              alignItems="center"
            >
              <SuspenseWrapper
                loading={loading}
                skeletonProps={{
                  variant: "circular",
                  width: "40px",
                  height: "40px",
                  animation: "wave",
                }}
              >
                <Tooltip
                  title="Ajouter au panier"
                  sx={{ color: "white", backgroundColor: "#104b7b" }}
                >
                  <IconButton
                    sx={{ color: "#104b7b" }}
                    size="large"
                    onClick={handleAddToCart}
                  >
                    <AddShoppingCartIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </SuspenseWrapper>
            </Box>
          </CardContent>
        </Card>
      </motion.div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
