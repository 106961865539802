import { ClientIcon, ProductIcon, SecurityIcon } from "./Icon";

export const breakpoints = {
  mobile: 480,
  tablet: 768,
  pc: 1024,
};

export const slideShowImages = [
  require("../../assets/images/Bsb_affiche.png"),
  require("../../assets/images/joysticks.jpg"),
  require("../../assets/images/peaky-blinders.jpg"),
];

export const valeurs =[
  {tag:'Paiment sécurisé ' ,icon:<SecurityIcon/>},
  {tag:'Produits de qualité' ,icon:<ProductIcon/>},
  {tag:'Client Statisfait' ,icon:<ClientIcon/>},
]
export const sampleProducts = [
  {
    id:'5545',
    category: 'Category 1',
    name: 'cartes cadeaux Netflix',
    image: 'https://via.placeholder.com/140', // Replace with actual image URL
    price: 10,
    currency: '€',
    priceUSD:13,
    country: 'France',
    countryCode: 'FR',
  },
  {
    id:'554f5',
    category: 'Category 2',
    name: 'cartes cadeaux Amazon',
    image: 'https://via.placeholder.com/140', // Replace with actual image URL
    price: 25,
    currency: '$',
    priceUSD:13,
    country: 'Canada',
    countryCode: 'CA',
  },
  {
    id:'554fds5',
    category: 'Category 3',
    name: 'cartes cadeaux Google Play',
    image: 'https://via.placeholder.com/140', // Replace with actual image URL
    price: 15,
    currency: '$',
    priceUSD:13,
    country: 'UK',
    countryCode: 'GB',
  },
  {
    id:'5545dd',
    category: 'Category 1',
    name: 'cartes cadeaux Netflix',
    image: 'https://via.placeholder.com/140', // Replace with actual image URL
    price: 10,
    currency: '€',
    priceUSD:13,
    country: 'France',
    countryCode: 'FR',
  },
  
];

export const steps = [
  {step:"etape 1"},
  {step:"etape 2"},
  {step:"etape 3"},
  {step:"etape 4"},
  {step:"etape 5"},

]

export const ItemTypes = {
  CONTENT_PART: 'contentPart',
};

export const primaryColor = "#104b7b"

export const authToken = "token";
