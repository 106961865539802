import { motion } from "framer-motion";
import logo from "../../assets/Bsb.png";

export const Loading = () => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50"
      style={{ backdropFilter: "blur(2px)" }}
    >
      <div className="flex flex-col items-center">
        <img src={logo} alt="Logo" className="w-1/3 mx-auto" />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="w-2/3 h-2 bg-gray-200 rounded-full overflow-hidden mt-4"
        >
          <motion.div
            className="h-full bg-primary"
            initial={{ width: "0%" }}
            animate={{ width: "100%" }}
            transition={{ duration: 5, repeat: Infinity, ease: "linear" }}
          />
        </motion.div>
      </div>
    </div>
  );
};
