import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { motion } from "framer-motion";
import { Header } from "components/shared";
import { useUtility } from "utils/context/UtilityContext";
import { apiSrcUrl, getToken } from "utils";

interface StatsType {
  totalBenefit?: string;
  totalActualBenefit?: string;
  totalRechargeBSB?: string;
  totalConvertedUSD?: string;
  totalGiftCards?: number;
  totalSold?: number;
  totalNotSold?: number;
}

interface ManualRechargeStats {
  totalRecharge: number;
  totalTransactions: number;
}

export const DashboardOperations = () => {
  const [userRechargeStats, setUserRechargeStats] = useState<StatsType | null>(
    null
  );
  const [giftCardStats, setGiftCardStats] = useState<any | null>(null);
  const [manualRechargeStats, setManualRechargeStats] =
    useState<ManualRechargeStats | null>(null);
  const [loading, setLoading] = useState(true);
  const { marginBottomHeader } = useUtility();
  // For dynamic chart
  const [selectedData, setSelectedData] = useState({
    totalBenefit: true,
    totalActualBenefit: true,
    totalSold: true,
    totalNotSold: true,
  });

  useEffect(() => {
    const fetchStats = async () => {
      const token = getToken(); // Retrieve the token
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };

      try {
        const [rechargeResponse, giftCardResponse, manualRechargeResponse] =
          await Promise.all([
            fetch(`${apiSrcUrl}/private/stats/user_recharge`, {
              method: "GET",
              headers,
            }),
            fetch(`${apiSrcUrl}/private/stats/giftcard_operations`, {
              method: "GET",
              headers,
            }),
            fetch(`${apiSrcUrl}/private/stats/manual_recharge`, {
              method: "GET",
              headers,
            }),
          ]);

        const rechargeData = await rechargeResponse.json();
        const giftCardData = await giftCardResponse.json();
        const manualRechargeData = await manualRechargeResponse.json();

        console.log(rechargeData);
        console.log(giftCardData);
        setUserRechargeStats(rechargeData.statistics);
        setGiftCardStats(giftCardData.statistics);
        setManualRechargeStats(manualRechargeData.statistics);
      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSelectedData((prev) => ({ ...prev, [name]: checked }));
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 p-4`}
    >
      <Header secondPart={false} />
      <Box p={4}>
        <Typography variant="h4" gutterBottom>
          Statistics Dashboard
        </Typography>
        <Grid container spacing={4}>
          {/* Full-Screen Pie Chart */}
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Typography
                variant="h3"
                gutterBottom
                align="center"
                sx={{ fontWeight: "bold", mb: 2 }}
              >
                Benefits Actual :{" "}
                {`${
                  parseFloat(userRechargeStats?.totalBenefit || "0") +
                  parseFloat(giftCardStats?.totalActualBenefit || "0") +
                  (manualRechargeStats?.totalRecharge || 0)
                } USD 💸💸`}
              </Typography>

              <PieChart
                series={[
                  {
                    data: [
                      {
                        id: 0,
                        value: parseFloat(
                          userRechargeStats?.totalBenefit || "0"
                        ),
                        label: "Total Benefit",
                      },
                      {
                        id: 1,
                        value: parseFloat(
                          giftCardStats?.totalActualBenefit || "0"
                        ),
                        label: "Actual Benefit",
                      },
                      {
                        id: 2,
                        value: manualRechargeStats?.totalRecharge || 0,
                        label: "Manual Recharge",
                      },
                    ],
                  },
                ]}
                width={window.innerWidth * 0.6} // Adjust width to 60% of screen
                height={400}
              />
            </Box>
          </Grid>

          {/* Bar Chart */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Gift Card and Recharge Stats
            </Typography>
            <BarChart
              series={[
                {
                  data: [
                    parseFloat(giftCardStats?.totalPossibleBenefit || "0"),
                    parseFloat(userRechargeStats?.totalBenefit || "0") +
                      parseFloat(
                        giftCardStats?.totalActualBenefit ||
                          "0" + manualRechargeStats?.totalTransactions ||
                          "0"
                      ),
                    userRechargeStats?.totalBenefit || 0,
                    giftCardStats?.totalActualBenefit +
                      manualRechargeStats?.totalTransactions || 0,
                    giftCardStats?.totalNotSold || 0,
                  ],
                  label: "Statistics",
                },
              ]}
              xAxis={[
                {
                  scaleType: "band",
                  data: [
                    "Possible Benefits",
                    "Actual Benefits",
                    "User Recharges",
                    "Gift Card Sold",
                    "Not Sold",
                  ],
                },
              ]}
              width={400}
              height={300}
            />
          </Grid>

          {/* Line Chart */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Interactive Chart
            </Typography>
            <FormGroup row>
              {Object.keys(selectedData).map((key) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={selectedData[key as keyof typeof selectedData]}
                      onChange={handleCheckboxChange}
                      name={key}
                    />
                  }
                  label={key}
                />
              ))}
            </FormGroup>
            <LineChart
              series={
                [
                  selectedData.totalBenefit && {
                    id: "totalBenefit",
                    label: "Total Benefit",
                    data: [parseFloat(userRechargeStats?.totalBenefit || "0")],
                  },
                  selectedData.totalActualBenefit && {
                    id: "totalActualBenefit",
                    label: "Actual Benefit",
                    data: [
                      parseFloat(
                        giftCardStats?.totalActualBenefit ||
                          "0" + manualRechargeStats?.totalTransactions ||
                          "0"
                      ),
                    ],
                  },
                  selectedData.totalSold && {
                    id: "totalSold",
                    label: "Sold",
                    data: [giftCardStats?.totalSold || 0],
                  },
                  selectedData.totalNotSold && {
                    id: "totalNotSold",
                    label: "Not Sold",
                    data: [giftCardStats?.totalNotSold || 0],
                  },
                ].filter(Boolean) as any
              }
              xAxis={[{ scaleType: "point", data: ["Benefit Stats"] }]}
              width={400}
              height={300}
            />
          </Grid>
        </Grid>
      </Box>
    </motion.div>
  );
};
