import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Typography, Box, Paper, CardMedia } from "@mui/material";
import { fetchPublic, MottoType, useWindowSize } from "../../utils";
import SuspenseWrapper from "./SuspenseWrapper";
interface Props {
  loading?: boolean;
}
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0 },
};

export const Motto: React.FC<Props> = ({ loading = false }) => {
  const [mottos, setMottos] = useState<MottoType[]>([]);
  const { isMobile, isPC } = useWindowSize();
  const [loadingMotto, setLoadingMotto] = useState(true);
  const [expanded, setExpanded] = useState<number[]>([]);
  const controls = useAnimation();
  const { ref: mottoRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    setLoadingMotto(true);
    const fetchMottos = async () => {
      const result = await fetchPublic(`/public/info/mottos`);
      if (result.info) {
        setMottos(Object.values(result.info));
      }
      setLoadingMotto(false);
    };

    fetchMottos();
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const handleExpandClick = (index: number) => {
    if (expanded.includes(index)) {
      setExpanded(expanded.filter((i) => i !== index));
    } else {
      setExpanded([...expanded, index]);
    }
  };

  return (
    <Box
      sx={{
        padding: "16px",
        margin: "16px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <motion.div
        ref={mottoRef}
        className="flex w-full items-center justify-between  flex-col"
        variants={containerVariants}
        initial="hidden"
        animate={controls}
      >
        {mottos.map((motto, index) => (
          <motion.div
            key={index}
            className="flex gap-4 items-center justify-center  flex-row w-full "
            variants={itemVariants}
          >
            {isPC && (
              <Box
                className="w-1/5 h-full bg-gray"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <SuspenseWrapper
                  loading={loadingMotto}
                  skeletonProps={{
                    variant: "rounded",
                    width: "20%",
                    height: "200px",
                    animation: "wave",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={
                      "https://firebasestorage.googleapis.com/v0/b/bsb-project-99087.appspot.com/o/images%2Flogos%2FLogoWithNames.jpg?alt=media&token=7d8f0f73-90b8-47f5-bc5a-724589b27512"
                    }
                    alt={"logoWithName"}
                    sx={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </SuspenseWrapper>
              </Box>
            )}

            <Box
              className={`flex flex-col ${
                isMobile ? "w-full" : "w-4/5"
              } items-center justify-center `}
              sx={{ width: isMobile ? "100%" : "75%" }}
            >
              <SuspenseWrapper
                loading={loadingMotto}
                skeletonProps={{
                  variant: "rounded",
                  width: "100%",
                  height: "300px",
                  animation: "wave",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    cursor: "pointer",
                  }}
                  onClick={() => handleExpandClick(index)}
                >
                  <Paper
                    className=" bg-gray"
                    elevation={3}
                    sx={{
                      padding: "16px",
                      margin: "16px",
                      width: isMobile ? "100%" : "auto",
                      background: "#f5f5f5",
                    }}
                  >
                    <Typography
                      variant="h6"
                      textAlign={"justify"}
                      dangerouslySetInnerHTML={{ __html: motto.motto }}
                    />
                  </Paper>
                </Box>
              </SuspenseWrapper>
            </Box>
          </motion.div>
        ))}
      </motion.div>
    </Box>
  );
};
