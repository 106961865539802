import {
  fetchPublic,
  ProductType,
  useAuthListener,
  useWindowSize,
} from "../../utils";
import { useEffect, useState } from "react";
import {
  BestSeller,
  Footer,
  Header,
  Loading,
  Motto,
  OrderSteps,
  Subtitle,
  SuspenseWrapper,
  Values,
} from "../shared";
import { motion } from "framer-motion";
import React from "react";
import { CssBaseline } from "@mui/material";
import { useUtility } from "../../utils/context/UtilityContext";
import { ProductList } from "../shared";
import { FeedbackForm } from "components/forms";
import { VideoDisplay } from "components/manager";
const Slideshow = React.lazy(() => import("../shared/SlideShow"));

export const Home = () => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState(true);
  const { marginBottomHeader } = useUtility();
  const [youtubeUrl, setYoutubeUrl] = useState<string>("");
  const { isMobile } = useWindowSize();
  useAuthListener();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/product/topSells?limit=10`);
    if (result.topProducts) {
      const productsArray = Object.values(result.topProducts) as ProductType[];
      setProducts(productsArray.slice(0, 8));
    }
    setLoading(false);
  };
  useEffect(() => {
    const fetchYoutubeUrl = async () => {
      try {
        const result = await fetchPublic("/public/info/youtubeUrl");
        if (result && result.info.youtubeUrl) {
          setYoutubeUrl(result.info.youtubeUrl);
        }
      } catch (error) {
        console.error("Error fetching YouTube URL:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchYoutubeUrl();
  }, []);


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll`}
    >
      <Header />
      {loading && <Loading />}
      <div
        className={`flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll w-full h-full ${
          isMobile ? "mb-64" : `mb-32`
        }`}
      >
        <CssBaseline />

        <SuspenseWrapper
          loading={loading}
          skeletonProps={{
            variant: "rounded",
            width: "100%",
            height: isMobile ? "300px" : "500px",
            animation: "wave",
          }}
        >
          <Slideshow />
        </SuspenseWrapper>

        <div
          className={`absolute ${
            isMobile ? "top-1/3" : `top-1/2`
          } right-1/2 left-1/2 `}
        >
          <SuspenseWrapper
            loading={loading}
            skeletonProps={{
              variant: "rounded",
              width: "100%",
              height: "300px",
              animation: "wave",
              sx: { bgcolor: "red" },
            }}
          >
            <BestSeller />
          </SuspenseWrapper>
        </div>
      </div>
      <div className="mt-1 flex w-full items-center justify-center">
        <Values loading={loading} />
      </div>

      <Subtitle title={"Produits populaires"} loading={loading} />
      <ProductList products={products} loading={loading} />

      <SuspenseWrapper
        loading={loading && youtubeUrl !== ""}
        skeletonProps={{
          variant: "rounded",
          width: "50%",
          height: "300px",
          animation: "wave",
        }}
      >
        {youtubeUrl && (
          <VideoDisplay
            videoLink={youtubeUrl}
            width={isMobile ? "100%" : "80%"}
          />
        )}
      </SuspenseWrapper>

      <Subtitle title={"Etapes pour une commande"} loading={loading} />
      <div
        className={`flex flex-col items-center justify-start gap-4 px-4 md:px-8 lg:px-32 xl:px-64 w-full`}
      >
        <OrderSteps loading={loading} />
        <Motto loading={loading} />
      </div>
      <SuspenseWrapper
        loading={loading && youtubeUrl !== ""}
        skeletonProps={{
          variant: "rounded",
          width: "70%",
          height: "500px",
          animation: "wave",
        }}
      >
        <FeedbackForm />
      </SuspenseWrapper>

      <Footer />
    </motion.div>
  );
};
