import React, { ReactNode } from "react";
import Skeleton from "@mui/material/Skeleton";
import { SxProps, Theme } from "@mui/material";

interface SuspenseWrapperProps {
  children: ReactNode;
  fallback?: ReactNode;
  loading?: boolean;
  skeletonProps?: {
    variant?: "text" | "circular" | "rectangular" | "rounded";
    width?: number | string;
    height?: number | string;
    animation?: "pulse" | "wave" | false;
    sx?: SxProps<Theme>;
  };
}

export const SuspenseWrapper: React.FC<SuspenseWrapperProps> = ({
  children,
  fallback,
  loading = false,
  skeletonProps = { variant: "rectangular", width: "100%", height: "200px" },
}) => {
  if (loading) {
    return <>{fallback || <Skeleton {...skeletonProps} />}</>;
  }

  return (
    <React.Suspense fallback={fallback || <Skeleton {...skeletonProps} />}>
      {children}
    </React.Suspense>
  );
};

export default SuspenseWrapper;
